export default {
  state: {
    miniSideBar: false,
    activeUserCount: 0,
    activeAccountCount: 0,
  },
  mutations: {
    setMiniSidebar(state, sidebar) {
      state.miniSideBar = sidebar;
    },
    resetUserState(state) {
      state.user = null;
      if (state.activeUserCount > 0) {
        state.activeUserCount--;
      }
    },
    setUser(state, user) {
      state.user = user;
      state.activeUserCount++;
    },
    setActiveUserCount(state, count) {
      state.activeUserCount = count;
    },
    setActiveAccountCount(state, count) {
      state.activeAccountCount = count;
    },
  },
  actions: {
    getMiniSidebar({ commit }) {
      commit("setMiniSidebar", true);
    },
    login({ commit }, user) {
      commit("setUser", user);
    },
    logout({ commit }) {
      commit("resetUserState");
      localStorage.clear();
    },
    updateActiveUserCount({ commit }, count) {
      commit("setActiveUserCount", count);
    },
    updateActiveAccountCount({ commit }, count) {
      commit("setActiveAccountCount", count);
    },
  },
  getters: {
    miniSideBar: (s) => s.miniSideBar,
    activeUserCount: (s) => s.activeUserCount,
    activeAccountCount: (s) => s.activeAccountCount,
  },
};
