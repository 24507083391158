<template>
  <v-app id="app" class="application_style">
    <v-navigation-drawer
      v-if="shouldShowSidebar"
      app
      v-model="drawer"
      :mini-variant.sync="mini"
      permanent
    >
      <Sidebar
        :menuItem1="items"
        :menuItem2="items1"
        @toggleSidebar="mini = !mini"
      />
    </v-navigation-drawer>
    <v-main class="ex-main-container">
      <div class="main-contant">
        <NotFoundView
          v-if="getRouteError.code == 404 && getRouteError.visibility == true"
        />
        <router-view
          v-if="getRouteError.visibility == false"
          :key="$route.path"
        />
      </div>
      <ExLoader v-if="isLoading" text="Loading..." :type="loaderType" />
    </v-main>
  </v-app>
</template>

<script>
import Sidebar from "@components/sidebar/SideBar.vue";
import { knock } from "@/plugins/knock";
import ExLoader from "@components/common/ExLoader.vue";
import "vue-loading-overlay/dist/vue-loading.css";
import NotFoundView from "@components/PageNotFound/NotFound.vue";
import { getSystemUsers, getListOfAccounts } from "@/api";

let user = localStorage.getItem("user");
let knockFeed = null;

if (user) {
  user = JSON.parse(user);
  knock.authenticate(user.email);

  knockFeed = knock.feeds.initialize(
    process.env.VUE_APP_KNOCK_FEED_CHANNEL_ID,
    {
      archived: "exclude",
      page_size: 25,
    }
  );

  knockFeed.listenForUpdates();

  knockFeed.on("items.received.realtime", ({ items }) => {
    console.log(items);
  });
}

export default {
  name: "App",
  components: {
    ExLoader,
    Sidebar,
    NotFoundView,
  },

  mounted() {
    const LoginAuth = localStorage.getItem("token");
    if (LoginAuth != null) {
      this.fetchAdminCount();
    }
  },

  methods: {
    async fetchAdminCount() {
      const { data: userList } = await getSystemUsers(1);
      const { data: accountsList } = await getListOfAccounts();

      const activeUserCount = userList.results.filter(
        (item) => item.state == "operational"
      ).length;

      const activeAccountCount = accountsList.results.filter(
        (item) => item.status == "open"
      ).length;

      this.$store.commit("setActiveUserCount", activeUserCount);
      this.$store.commit("setActiveAccountCount", activeAccountCount);
    },
  },

  data: () => ({
    drawer: true,
    mini: false,
    // items: [
    //   {
    //     title: "Accounts",
    //     icon: require("./assets/sidebar/account-icon.svg"),
    //     count: localStorage.getItem("active_accounts_count") || 0,
    //     to: "/accounts",
    //   },
    //   {
    //     title: "Subscriptions",
    //     icon: require("./assets/sidebar/subscription-icon.svg"),
    //     count: 0,
    //     to: "/subscriptions",
    //   },
    //   {
    //     title: "Users",
    //     icon: require("./assets/sidebar/user-Icon.svg"),
    //     count: 0,
    //     to: "/users",
    //   },
    //   {
    //     title: "Partners",
    //     icon: require("./assets/sidebar/partners-icon.svg"),
    //     count: 0,
    //     to: "#",
    //   },
    // ],
    items1: [
      {
        title: "Support",
        icon: require("./assets/sidebar/support.svg"),
        to: "/#",
      },
      {
        title: "System Settings",
        icon: require("./assets/sidebar/settings.svg"),
        to: "/#",
      },
    ],
  }),
  computed: {
    getRouteError() {
      return this.$store.state.routeErrorHandling.error;
    },
    isLoading() {
      return this.$store.state.isLoading.isLoading;
    },
    loaderType() {
      return this.$store.state.isLoading.loaderType;
    },
    shouldShowSidebar() {
      return this.$route.meta.sidebar !== false;
    },
    activeUserCount() {
      return this.$store.getters.activeUserCount;
    },
    items() {
      return [
        {
          title: "Accounts",
          icon: require("./assets/sidebar/account-icon.svg"),
          count: this.$store.getters.activeAccountCount, // Computed dynamically
          to: "/accounts",
        },
        {
          title: "Subscriptions",
          icon: require("./assets/sidebar/subscription-icon.svg"),
          count: 0,
          to: "/subscriptions",
        },
        {
          title: "Users",
          icon: require("./assets/sidebar/user-Icon.svg"),
          count: this.$store.getters.activeUserCount, // Computed dynamically
          to: "/users",
        },
        {
          title: "Partners",
          icon: require("./assets/sidebar/partners-icon.svg"),
          count: 0,
          to: "#",
        },
      ];
    },
  },
  watch: {
    activeUserCount(newCount) {
      const userItem = this.items.find((item) => item.title === "Users");
      if (userItem) {
        userItem.count = newCount;
      }
    },
  },
};
</script>
<style>
.v-main__wrap {
  display: flex;
}
.application_style {
  font-family: "Inter" !important;
}
.main-contant {
  width: 100%;
  height: 100%;
  padding: 15px;
}
.main-contant section {
  height: 100%;
}
.ex-main-container {
  scrollbar-width: none;
}
</style>
